<div id="loading"
     [hidden]="!show">
    <div style="width:100%;height:100%"
         class="lds-wedges">
        <div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
        </div>
        <div class="text_loading"> CARGANDO...</div>
    </div>
</div>